<template>
  <div class="home">
    <div class="navbar-box">
      <van-nav-bar title="Mengma" :safe-area-inset-top="true" ref="navBarRef">
        <template #title>
          <div class="search" @click="to_search">
            <div class="img">
              <van-image
                :src="require('./img/search-icon.png')"
                fit="contain"
              />
            </div>
            <span>Search keywords</span>
          </div>
        </template>
      </van-nav-bar>
    </div>

    <div class="banner div-bg" @click="to_vip">
      <img :src="require(`./img/banner.png`)" alt="" />
      <div class="bg"></div>
    </div>

    <div class="recommend div-bg" @click="to_recommend">
      <van-image width="100%" :src="require(`./img/recommend.png`)" />
      <div class="bg"></div>
    </div>

    <div class="modules">
      <div class="modules-item div-bg" @click="to_new_arrivals">
        <img width="100%" :src="require(`./img/modules-item-1.png`)" alt="" />
        <div class="bg"></div>
      </div>
      <div class="modules-item div-bg" @click="to_almost_sold">
        <img width="100%" :src="require(`./img/modules-item-2.png`)" alt="" />
        <div class="bg"></div>
      </div>
    </div>

    <div
      class="special-recommendations div-bg"
      @click="to_special_recommendations"
    >
      <img
        width="100%"
        :src="require(`./img/special-recommendations.png`)"
        alt=""
      />
      <div class="bg"></div>
    </div>

    <div class="category div-bg" @click="to_search">
      <van-image width="100%" :src="require(`./img/category.png`)" />
      <div class="bg"></div>
    </div>

    <div class="product-box">
      <productCard />
    </div>

    <Tabbar name="home" />

    <div class="modal-box">
      <van-dialog v-model="show" :showConfirmButton="false" width="37.5rem">
        <div class="dialog-box">
          <div class="login" v-if="showLogin">
            <van-image width="100%" :src="require('./img/login.png')" />
            <div class="bg" @click="showLogin = false"></div>
          </div>

          <div class="login" v-else>
            <van-image width="100%" :src="require('./img/login1.png')" />
            <div class="bg" @click="to_my"></div>
          </div>

          <div class="close" @click="show = false"></div>
        </div>
      </van-dialog>

      <van-dialog v-model="show2" :showConfirmButton="false" width="37.5rem">
        <div class="dialog-box">
          <van-image width="100%" :src="require('./img/contact-us.png')" />
          <div class="close" @click="show2 = false"></div>
          <div class="bg" @click="show2 = false"></div>
        </div>
      </van-dialog>
    </div>
  </div>
</template>

<script>
import productCard from "@/components/product-card";
import Tabbar from "@/components/tabbar";
export default {
  components: { productCard, Tabbar },
  data() {
    return {
      show: false,
      show2: false,
      product_list: [],
      showLogin: true,
    };
  },
  mounted() {},
  methods: {
    to_product_details(id) {
      this.$router.push({ path: "/product-details", query: { id: id } });
    },
    to_special_recommendations() {
      this.$router.push({ path: "/special-recommendations" });
    },
    to_my() {
      this.$router.push({ path: "/my" });
    },
    to_vip() {
      this.$router.push({ path: "/vip" });
    },
    to_search() {
      this.$router.push({ path: "/search" });
    },
    to_recommend() {
      this.$router.push({ path: "/recommend" });
    },
    to_new_arrivals() {
      this.$router.push({ path: "/new-arrivals" });
    },
    to_almost_sold() {
      this.$router.push({ path: "/almost-sold" });
    },
  },
};
</script>

<style lang="scss">
.home {
  background-color: #f1f1f1;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .navbar-box {
    position: sticky;
    top: 0;
    border-radius: 0 0 0.25rem 0.25rem;
    overflow: hidden;
    z-index: 999;
    border-bottom: 1px solid #ffffff;

    .van-nav-bar__title {
      max-width: none;
    }

    .search {
      width: 43.875rem;
      height: 4.125rem;
      border-radius: 2.5rem;
      border: 0.125rem solid #000000;
      display: flex;
      align-items: center;
      padding-left: 1.125rem;

      .img {
        width: 2rem;
        height: 2rem;
      }

      span {
        font-size: 1.75rem;
        color: #636768;
        line-height: 2.5rem;
        font-weight: normal;
        margin-left: 1rem;
      }
    }
  }

  .banner {
    padding: 0.75rem 1.5rem 1.25rem;
    background-color: #ffffff;
    margin-bottom: 0.75rem;
    img {
      width: 100%;
    }
  }

  .recommend {
    position: relative;
    margin-bottom: 0.75rem;
  }

  .modules {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.75rem;

    .modules-item {
      width: calc((100% - 0.75rem) / 2);
      background-color: #ffffff;
    }
  }

  .special-recommendations {
    background-color: #ffffff;
    margin-bottom: 0.75rem;
  }

  .category {
    position: sticky;
    top: 5.5rem;
    z-index: 99;
  }

  .product-box {
    // padding: 0 1.5rem;
    // margin-top: 1.25rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 6.875rem;

    .product-item {
      width: calc((100% - 1.25rem) / 2);
      background-color: #ffffff;
      margin-bottom: 1.25rem;
      border-radius: 0.625rem;

      // .product-item-content {
      //   padding: 1.25rem;
      // }
    }
  }

  .dialog-box {
    .close {
      width: 5.25rem;
      height: 5.25rem;
      top: 0;
      right: 0;
      position: absolute;
      z-index: 5;
    }

    .bg {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
</style>